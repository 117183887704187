<template>
  <div class="row">
    <retry-notification
      :show="error"
      @retry="updateData()"
    />
    <div class="flex xs12">
      <va-card :title="$t('inventory_requests.edit')">
        <actions
          slot="actions"
          crud-links="inventoryRequests"
          :actions="actions"
          :action-data="{id: $route.params.id}"
        />
        <data-form
          :request="inventoryRequest"
          :loading="loading"
          @submit="submit"
        />
      </va-card>
    </div>

  </div>
</template>

<script>
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const DataForm = () => import(/* webpackPrefetch: true */ './Form')

export default {
  name: 'inventory-requests-edit',
  components: {
    Actions,
    DataForm,
  },
  data () {
    return {
      error: false,
      loading: false,
      headers: { 'Content-Type': 'multipart/form-data' },
      actions: ['index', 'new', 'view', 'delete'],
      inventoryRequest: {},
    }
  },
  created () {
    this.updateData()
  },
  methods: {
    // FIXME
    routeBuilder (id) {
      return `inventory/requests/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const requestId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(requestId))
      } catch (err) {
        // console.log('Error fetching request data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.inventoryRequest = { ...u.data.data }
    },
    async submit (request) {
      this.loading = true
      request.append('_method', 'PUT')

      try {
        await this.$http.post(this.routeBuilder(request.get('id')), request, { headers: this.headers })
      } catch (error) {
        // console.log('Error updating data', error)
        this.loading = false
        return
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
    },
  },
}
</script>
